import "./careeradvice.css";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const BlogCard = (card) => {
  const navigate = useNavigate();
  return (
    <div className="blog-card">
      <div className="blog-head">
        <h3>{card?.data?.title}</h3>
        <div
          className="truncated-text"
          dangerouslySetInnerHTML={{ __html: card?.data?.content }}
        ></div>
        <button
          onClick={() => navigate(`/blog/${card?.data?._id}`)}
          className="read-more-btn"
        >
          Read More
        </button>
      </div>
      <img src={card?.data?.image} alt={card?.data?.title} />
    </div>
  );
};

export default BlogCard;
