import React from "react";
import "./hero_four.css";
import Photo from "./herofinal.png";
import { useNavigate } from "react-router-dom";

function HeroFour() {
  const navigate = useNavigate();

  const animateText = (
    text,
    delay = 0,
    color = "transparent",
    gradient = false,
    fontWeight = "normal"
  ) => {
    return text.split(" ").map((word, wordIndex) => (
      <React.Fragment key={wordIndex}>
        {word.split("").map((char, charIndex) => (
          <span
            key={charIndex}
            style={{
              animationDelay: `${
                (wordIndex * word.length + charIndex) * 0.05 + delay
              }s`,
              color: gradient ? "transparent" : color,
              background: gradient
                ? "linear-gradient(to right, #f03716, #f45337, #ee712e)"
                : "unset",
              WebkitBackgroundClip: gradient ? "text" : "unset",
              WebkitTextFillColor: gradient ? "transparent" : color,
              fontWeight: gradient ? fontWeight : "normal",
            }}
          >
            {char}
          </span>
        ))}
        {wordIndex < text.split(" ").length - 1 && <span>&nbsp;</span>}{" "}
        {/* Add space between words, but not after the last word */}
      </React.Fragment>
    ));
  };

  return (
    <div className="hero-containerrr">
      <div className="content-containerrr">
        <h1>
          {animateText("The Right Candidate,", 0, "black")} {/* Black color */}
          <br />
          {animateText("The Right", 2, "black")} {/* "The Right" in black */}
          <span>&nbsp;</span>
          {animateText("Job.", 2.5, "transparent", true, "700")}{" "}
          {/* Gradient and bold */}
        </h1>
        <p>
          Matching the right candidate with the right job ensures a perfect fit,
          leading to greater productivity, job satisfaction, and long-term
          success for both the employer and employee relationship, fostering
          growth and achievement.
        </p>
        <div className="herobtnnn">
          <button
            onClick={() => {
              navigate("/signin?role=candidate");
            }}
            className="hero-button"
          >
            Log in
          </button>
          <button
            onClick={() => {
              navigate("/contact");
            }}
            className="hero-button1"
          >
            Contact Us
          </button>
        </div>
      </div>
      <div className="image-containerrr">
        <img src={Photo} alt="Hero" className="hero-image" />
      </div>
    </div>
  );
}

export default HeroFour;
