import React, { useCallback, useEffect, useState } from "react";
import "./jobfilter.css";
import { FaChevronDown, FaChevronUp, FaFilter } from "react-icons/fa";
import eztech from "./img/eztechlogo.png";
import accenture from "./img/Accenture-Logo.png";
import JobDetailsModal from "./jobDetailsModal";
import useSwr from "../../HOOKS/useSWR";
import { useLocation } from "react-router-dom";
import { HiArrowUturnRight } from "react-icons/hi2";
import queryString from "query-string";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const useQuery = () => {
  const { search } = useLocation();
  return queryString.parse(search);
};
function Jobfilter() {
  const { sear, expr, locn, companyName, industryName } = useQuery();
  const navigate = useNavigate();

  const [experience, setExperience] = useState(0);
  const [isWorkModeOpen, setWorkModeOpen] = useState(true);
  const [country, setCountry] = useState("");
  const [isExperienceOpen, setExperienceOpen] = useState(true);
  const [isSalaryOpen, setSalaryOpen] = useState(true);
  const [isIndustryOpen, setIndustryOpen] = useState(true);
  const [isCountryOpen, setCountryOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterSectionVisible, setFilterSectionVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const jobsPerPage = 5;
  const [search, setSearch] = useState();
  const [url, setUrl] = useState(`jobpost/getjobpost?user=active`);
  const location = useLocation();
  const [onSite, setOnsite] = useState(false);
  const [hybrid, setHybrid] = useState(false);
  const [remote, setRemote] = useState(false);
  const [industry, setIndustry] = useState("");
  const [company, setCompany] = useState("");
  const [user, setUser] = useState("active");
  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };
  useEffect(() => {
    setSearch(sear);
    setExperience(expr);
    setCountry(locn);
    setCompany(companyName);
    setIndustry(industryName);
  }, [sear, expr, locn, companyName, industryName]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      setSearch(searchTerm);
    }, 1000),
    [url]
  );

  useEffect(() => {
    let params = [];
    if (search || locn || expr || company || industry) {
      // setUrl(`jobpost/getjobpost?search=${search}`);

      if (search) params.push(`search=${search}`);
      if (locn) params.push(`location=${locn}`);
      if (expr) params.push(`minExpr=${expr}`);
      if (company) params.push(`companyName=${company}`);
      if (industry) params.push(`industry=${industry}`);
      let queryString = params.length ? `?${params.join("&")}` : "";
      setUrl(`${url.split("?")[0]}${queryString}`);
    } else {
      setUrl(`jobpost/getjobpost?user=active`);
    }
  }, [search, company, locn, expr, industry]);

  const handleApply = () => {
    setExperienceOpen(true);
    setSalaryOpen(true);
    setCountryOpen(true);
    setWorkModeOpen(true);
    setIndustryOpen(true);
    let params = [];

    if (!search) {
      params.push(`user=active`);
      if (onSite) params.push("modeOfWork=onsite");
      if (hybrid) params.push("modeOfWork=hybrid");
      if (remote) params.push("modeOfWork=remote");
      if (Number(experience) >= 0 && (onSite || hybrid || remote)) {
        params.push(`minExpr=${experience}`);
      }
      if (country) params.push("location=" + country);
      if (industry) params.push("industry=" + industry);
    }
    if (Number(experience) >= 0 && !onSite && !hybrid && !remote) {
      params.push(`minExpr=${experience}`);
    }

    let queryString = params.length ? `?${params.join("&")}` : "";
    setUrl(`${url.split("?")[0]}${queryString}`);
  };

  const { data: jobData } = useSwr(url);

  const { data: categories } = useSwr("jobcategory/getallcategory") || [];

  const toggleSection = (section) => {
    switch (section) {
      case "workMode":
        setWorkModeOpen(!isWorkModeOpen);
        break;
      case "experience":
        setExperienceOpen(!isExperienceOpen);
        break;
      case "salary":
        setSalaryOpen(!isSalaryOpen);
        break;
      case "industry":
        setIndustryOpen(!isIndustryOpen);
        break;
      case "country":
        setCountryOpen(!isCountryOpen);
        break;
      default:
        break;
    }
  };

  const jobs = jobData?.data?.data?.data || [];
  const { data: jobsCountry } = useSwr("jobpost/getjobpost");

  const countries = [
    ...new Set(
      jobsCountry?.data?.data?.data.map((el) => el?.location?.country)
    ),
  ];
  // Calculate the jobs to display based on the current page
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Open Modal
  const openModal = (job) => {
    setSelectedJob(job);
    setModalOpen(true);
  };

  // Close Modal
  const closeModal = () => {
    setSelectedJob(null);
    setModalOpen(false);
  };
  const refreshHandler = () => {
    navigate(`/jobfilter`);
    setSearchQuery(``);
    setSearch(``);
    setOnsite("");
    setHybrid("");
    setRemote("");
    setIndustry("");
    setCountry("");
    setExperience(0);
    setExperienceOpen(false);
    setSalaryOpen(false);
    setCountryOpen(false);
    setWorkModeOpen(false);
    setIndustryOpen(false);
  };
  return (
    <div className="job-filter-container">
      <div
        className="filter-toggle-icon"
        onClick={() => setFilterSectionVisible(!isFilterSectionVisible)}
      >
        <FaFilter />
      </div>
      <div
        className={`job-filter-section first-section ${
          isFilterSectionVisible ? "visible" : ""
        }`}
      >
        <div className="first-section-job">
          <div className="filter-heading">
            <div className="flex justify-start gap-20 items-center">
              <div>
                <button onClick={handleApply} className="job-filter-apply">
                  Apply Filters
                </button>
              </div>

              <div className="justify-start">
                <IoMdRefresh
                  onClick={refreshHandler}
                  className="cursor-pointer h-6 w-6 rounded-full hover:rotate-180 text-red-500 "
                />
              </div>
            </div>
          </div>

          <h3
            onClick={() => toggleSection("workMode")}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <span>Work Mode</span>
            {isWorkModeOpen ? (
              <FaChevronDown style={{ marginLeft: "8px", color: "#868686" }} />
            ) : (
              <FaChevronUp style={{ marginLeft: "8px", color: "red" }} />
            )}
          </h3>
          {isWorkModeOpen && (
            <div className="filter-check">
              <div>
                <label>
                  <input
                    onClick={() => {
                      setOnsite(!onSite);
                    }}
                    type="checkbox"
                  />
                  Work from office
                </label>
              </div>
              <div>
                <label>
                  <input
                    onClick={() => {
                      setHybrid(!hybrid);
                    }}
                    type="checkbox"
                  />
                  Hybrid
                </label>
              </div>
              <div>
                <label>
                  <input onClick={() => setRemote(!remote)} type="checkbox" />
                  Remote
                </label>
              </div>
            </div>
          )}

          <div className="filter-heading">
            <h3
              onClick={() => toggleSection("experience")}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <span>Experience (Years)</span>
              {isExperienceOpen ? (
                <FaChevronDown
                  style={{ marginLeft: "8px", color: "#868686" }}
                />
              ) : (
                <FaChevronUp style={{ marginLeft: "8px", color: "red" }} />
              )}
            </h3>
            {isExperienceOpen && (
              <div className="filter-experience">
                <input
                  type="range"
                  id="experience-range"
                  name="experience"
                  min="0"
                  max="30"
                  value={experience}
                  onInput={(e) => setExperience(e.target.value)}
                />
                <div
                  className="experience-value"
                  style={{ left: `calc(${(experience / 30) * 100}% - 20px)` }}
                >
                  {experience}
                </div>
              </div>
            )}
          </div>

          <h3
            onClick={() => toggleSection("salary")}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <span>Salary</span>
            {isSalaryOpen ? (
              <FaChevronDown style={{ marginLeft: "8px", color: "#868686" }} />
            ) : (
              <FaChevronUp style={{ marginLeft: "8px", color: "red" }} />
            )}
          </h3>
          {isSalaryOpen && (
            <div className="filter-check">
              <div>
                <label>
                  <input type="checkbox" />
                  0-3 Lakhs
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" />
                  3-6 Lakhs
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" />
                  6-10 Lakhs
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" />
                  10-15 Lakhs
                </label>
              </div>
            </div>
          )}

          <h3
            onClick={() => toggleSection("industry")}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <span>Industry</span>
            {isIndustryOpen ? (
              <FaChevronDown style={{ marginLeft: "8px", color: "#868686" }} />
            ) : (
              <FaChevronUp style={{ marginLeft: "8px", color: "red" }} />
            )}
          </h3>
          {isIndustryOpen && (
            <div className="filter-check-industry">
              {categories?.data?.data?.map((el, index) => (
                <div key={index}>
                  <label>
                    <input
                      type="radio"
                      name="industries"
                      value={el?.industryName.toUpperCase()}
                      onClick={() => setIndustry(el?._id)}
                    />
                    <span className="mx-2"></span>
                    {`${el?.industryName}`.toUpperCase()}
                  </label>
                </div>
              ))}
            </div>
          )}

          <h3
            onClick={() => toggleSection("country")}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <span>Country</span>
            {isCountryOpen ? (
              <FaChevronDown style={{ marginLeft: "8px", color: "#868686" }} />
            ) : (
              <FaChevronUp style={{ marginLeft: "8px", color: "red" }} />
            )}
          </h3>
          {isCountryOpen && (
            <div className="filter-check-country">
              {countries?.length &&
                countries?.map((el, index) => (
                  <div key={index}>
                    <label>
                      <input
                        type="radio"
                        name="country"
                        value={el.toUpperCase()}
                        onClick={() => setCountry(el)}
                      />
                      <span className="mx-2"></span>
                      {`${el}`.toUpperCase()}
                    </label>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className="job-filter-section second-section">
        <div className="job-filter-search-bar">
          <input
            type="text"
            placeholder="Search jobs..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e.target.value);
            }}
          />
        </div>
        {currentJobs?.length ? (
          currentJobs.map((job) => (
            <div key={job.id || job._id} className="filter-job-heading">
              <div className="list-job">
                <h1>{job.title || "Not Provided"}</h1>
                <img
                  src={job?.companyId?.logo || eztech}
                  alt="Company Logo"
                  className="logo-image"
                />
              </div>
              <div className="company-name">
                <h2>{job.companyName || "Not Provided"}</h2>
              </div>

              <div className="job-details">
                <div className="all-details">
                  <i className="fas fa-calendar-alt"></i>{" "}
                  {job.experience.min || 0} -{job.experience.max || 0} Years
                </div>
                <span>|</span>
                <div className="all-details">
                  <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                  {job.salary.min || 0}-{job.salary.max || 0} {job?.currency}
                </div>
                <span>|</span>
                <div className="all-details">
                  <i className="fas fa-map-marker-alt"></i>{" "}
                  {`${job.location.city || "Not Provided"}, ${
                    job.location.state || "Not Provided"
                  }, ${job.location.country || "Not Provided"}`}
                </div>
              </div>

              <div className="job-eligibility">
                <i className="fas fa-briefcase"></i> {""}
                {job.description || "Not Provided"}
              </div>
              <div className="apply-details-button">
                <button
                  className="job-details-button"
                  onClick={() => openModal(job)}
                >
                  <i className="fa-solid fa-circle-info"></i>{" "}
                  <span>View Details</span>
                </button>
              </div>
            </div>
          ))
        ) : (
          <h1 className="h-screen text-center mt-10 text-3xl font-bold">
            No Jobs Found
          </h1>
        )}
        {/* Pagination Controls */}
        <div className="pagination">
          {Array.from(
            { length: Math.ceil(jobs.length / jobsPerPage) },
            (_, i) => (
              <button
                key={i + 1}
                onClick={() => {
                  paginate(i + 1);
                }}
                className={currentPage === i + 1 ? "active" : ""}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      </div>
      {/* <div className="content-container"> */}
      <div className="job-filter-section third-section">
        <div className="job-box">
          <img
            src="./img/logo.png"
            alt="Company Logo"
            className="company-market-logo"
          />
          <h2>Boost your profile visibility among recruiters threefold.</h2>
          <p>
            Enhance your prospects of landing a callback with Talentfolder
            FastForward, and receive tailored guidance to ensure your profile
            shines brightly.
          </p>
        </div>
        {/* </div> */}
      </div>
      <JobDetailsModal
        isOpen={isModalOpen}
        job={selectedJob}
        onClose={closeModal}
      />
    </div>
  );
}

export default Jobfilter;
// Debounce function
